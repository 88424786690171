import * as _ from "lodash";
import log from "loglevel";
import { IObservableArray, IObservableValue, action, computed, configure, makeObservable, observable, runInAction } from "mobx";

import BaseManager from "../editor/managers/BaseManager";
import { SceneEditorMode } from "../editor/models/SceneEditorMode";
import { ValidationResult } from "../editor/models/ValidationResult";
import { ValidationMode } from "../editor/models/ValidationType";
import PdfReportTool from "../editor/tools/PdfReportTool";
import CorePlanCostTool from "../editor/tools/CorePlanCostTools";
import CorePlanJsonTool from "../editor/tools/CorePlanJsonTool";
import TotalMaterialListTool from "../editor/tools/TotalMaterialListTool";
import SceneUtils from "../editor/utils/SceneUtils";
import { catalogSettings } from "../entities/catalogSettings";
import { entityManager } from "../entities/entityManager";
import { fileManager } from "../entities/fileManager";
import { finishesManager } from "../entities/finishesManager";
import { revitFiles } from "../entities/revitFiles";
import settings from "../entities/settings/settings";
import { vtUser } from "../entities/user";
import { arrayIdSet, isWooMode } from "../helpers/utilities";
import { markHidden } from "../services/allKinds";
import { registerUserAction } from "../store/UserDepot";
import { IContextMenuOptions } from "../ui/components/common/ContextMenu";
import { IPromptOptions } from "../ui/components/common/Prompt";
import { ITooltipOptions } from "../ui/components/common/Tooltip";
import { DesignStyle, ExteriorFinish, Roof } from "./DesignStyle";
import { DxfItem } from "./DxfItem";
import { FileExportType } from "./FileExportType";
import { Floor } from "./Floor";
import MockupsManager from "./MockupsManager";
import { CorePlan } from "./CorePlan";
import { RoomCategory } from "./RoomCategory";
import RoomOpening from "./RoomOpening";
import { RoomType, AssetsState as RoomTypeAssetsState } from "./RoomType";
import { SceneDisplayUnits } from "./SceneDisplayUnits";
import { SceneMode } from "./SceneMode";
import { corePlanLockHeartbeat } from "../helpers/CorePlanLockHeartbeat";
import { DUTCH_GABLE_MINIMUM_WIDTH, DUTCH_GABLE_SMALL_ERROR } from "../constants";
import { IEditorToolOptions } from "../ui/components/Editor/EditorToolbar";

configure({ enforceActions: "observed" });
interface ActiveAreaColors {
  text: string;
  color: string;
  borderColor?: string;
  type: any;
}

export class AppModel {
  private bm: BaseManager = null;
  private _activeCorePlan: IObservableValue<CorePlan | null> = observable.box(null);

  // observable properties:
  public dxfStore: DxfItem[] = [];
  public roomCategories: RoomCategory[] = [];
  public corePlans: CorePlan[] = [];

  // helper properties:
  public isBusy: boolean = false;
  public isSceneLoaded: boolean = false;
  public isBackgroundScalingEnabled: boolean = false;
  // Unsaved changes in LotLine and Background edit mode
  public pendingChanges: boolean = false;

  public activeFloor: Floor = null;
  public selectedRoomsIds: string[] = [];
  public activeAreaColors: ActiveAreaColors[] = [];
  public selectedLotItemsIds: string[] = [];
  public selectedRoomOpenings: RoomOpening[] = [];
  public contextMenuOptions: IContextMenuOptions = { show: false };
  public tooltipOptions: ITooltipOptions = { show: false };
  public editToolOptions: IEditorToolOptions = { show: false };
  public promptOptions: IPromptOptions = { show: false };
  public sceneDisplayUnit: SceneDisplayUnits = SceneDisplayUnits.Inch;
  public gridUnitSizeInches: number = settings.values.validationSettings.gridCellSizeForNewCorePlans; // grid medium cell size, in inches
  public showAboveFloor = false;
  public showBelowFloor = false;
  public showRoof: boolean = false;
  public showCladding: boolean = true;
  public showBackground: boolean = true;
  public showGrid: boolean = true;
  public showGridOverPlan: boolean = false;
  public showObsoleteRooms: boolean = false;
  public snapToGrid: boolean = false;
  public activeValidationMode = ValidationMode.None;
  public validationResult: ValidationResult | null = null;
  public activeDesignStyle: DesignStyle = null;
  public activeFinish: ExteriorFinish = null;
  public dutchGableDepth: number = 12;
  public dutchGableError: string = "";
  public finishes: ExteriorFinish[] = [];
  public designStyles: DesignStyle[] = [];
  public active3dModelPresignedUrl: string = null;
  public includeCladdingThickness: boolean = false;
  public showFinishFaceDimension: boolean = false;

  public sceneMode: SceneMode = SceneMode.Editor;
  public sceneEditorMode: SceneEditorMode = SceneEditorMode.Room;
  public activeRoofId: string = null;

  constructor() {
    this.makeObs();
    registerUserAction("login", async (user: vtUser) => {
      await SceneUtils.ensureFontLoaded();
      await this.initData();
    });
  }

  get activeCorePlan(): CorePlan {
    return this._activeCorePlan.get();
  }

  get isViewOnlyMode(): boolean {
    if (this.activeCorePlan.lockedBy == null) return false;
    return !this.activeCorePlan?.isLockedByCurrentUser();
  }

  get baseManager(): BaseManager {
    if (!this.bm) {
      this.bm = new BaseManager();
    }

    return this.bm;
  }
  set baseManager(value: BaseManager | null) {
    if (this.bm && value) {
      throw new Error("baseManager is forbidden to change");
    }
    this.bm = value;
  }

  countRoomTypeInActiveCorePlan(roomFilter: (rt: RoomType, rc: RoomCategory) => boolean) {
    if (!this.activeCorePlan) {
      return 0;
    }

    let result = 0;
    for (const floor of this.activeCorePlan.floors) {
      for (const room of floor.rooms) {
        const roomType = this.getRoomType(room.roomTypeId);
        const roomCategory = this.getRoomCategory(roomType.roomCategoryId);
        if (roomFilter(roomType, roomCategory)) {
          result++;
        }
      }
    }

    return result;
  }

  get activeCorePlanBedroomsCounts(): number {
    return this.countRoomTypeInActiveCorePlan((rt, roomCategory) => roomCategory && roomCategory.isBedroom);
  }

  get activeCorePlanBathroomsCounts(): number {
    return this.countRoomTypeInActiveCorePlan((rt, roomCategory) => roomCategory && roomCategory.isBathroom);
  }

  addDxfItem(item: DxfItem): void {
    arrayIdSet(this.dxfStore, item);
  }

  setActiveAreaColors(colors: ActiveAreaColors[]): void {
    this.activeAreaColors = colors;
  }

  addRoomCategory(category: RoomCategory): void {
    this.roomCategories.push(category);
  }
  removeRoomCategory(categoryId: string): void {
    const index = this.roomCategories.findIndex(el => el.id == categoryId);
    if (index < 0) {
      return;
    }

    this.roomCategories.splice(index, 1);
  }
  getRoomCategory(categoryId: string): RoomCategory {
    const roomCategory = this.roomCategories.find(rc => rc.id === categoryId);
    if (!roomCategory) {
      log.error(`roomCategory ${categoryId} not found`);
    }
    return roomCategory;
  }

  removeFromRoomCategory(roomTypeId: string, categoryId?: string) {
    if (!categoryId) {
      const roomType = this.getRoomType(roomTypeId);
      categoryId = roomType?.roomCategoryId;
    }
    this.getRoomCategory(categoryId)?.removeRoomType(roomTypeId);
  }

  markRoomTypeHidden(roomTypeId: string) {
    const roomType = this.getRoomType(roomTypeId);
    if (!roomType) {
      log.error(`roomType ${roomTypeId} not found`);
      return;
    }
    runInAction(() => {
      roomType.mark = markHidden.id;
    });
  }

  getRoomType(roomTypeId: string): RoomType | undefined {
    let roomType: RoomType;

    this.roomCategories.some(roomCategory => {
      roomType = roomCategory.roomTypes.find(rt => rt.id === roomTypeId);
      return !!roomType;
    });

    return roomType;
  }

  getCatalogLastUpdatedDate(): string {
    return _.maxBy(this.roomCategories?.map(rc => rc.roomTypes).flat(), "createdAt")?.createdAt;
  }

  addFinish(finish: ExteriorFinish): void {
    arrayIdSet(this.finishes, finish);
  }
  deleteFinish(id: string): void {
    const idx = this.finishes.findIndex(f => f.id === id);
    if (idx !== -1) {
      this.finishes.splice(idx, 1);
    }
  }

  addDesignStyle(designStyle: DesignStyle): void {
    arrayIdSet(this.designStyles, designStyle);
  }

  addCorePlan(corePlan: CorePlan): void {
    arrayIdSet(this.corePlans, corePlan);
  }
  removeCorePlan(id: string): void {
    const idx = this.corePlans.findIndex(p => p.id === id);
    if (idx > -1) {
      this.corePlans.splice(idx, 1);
    }
  }

  setIsBusy(isBusy: boolean): void {
    this.isBusy = isBusy;
  }

  setIsSceneLoaded(isSceneLoaded: boolean): void {
    this.isSceneLoaded = isSceneLoaded;
  }

  setIsBackgroundScalingEnabled(isBackgroundScalingEnabled: boolean): void {
    this.isBackgroundScalingEnabled = isBackgroundScalingEnabled;
  }

  setPendingChanges(pendingChanges: boolean): void {
    this.pendingChanges = pendingChanges;
  }

  async setActiveCorePlan(corePlan: CorePlan | null | undefined, unlock = true): Promise<void> {
    if (this.activeCorePlan && this.activeCorePlan.isLockedByCurrentUser() && unlock) {
      corePlanLockHeartbeat.stop();
      await entityManager.unlockCorePlan(this.activeCorePlan, false);
    }

    if (corePlan != null) {
      // Load missing assets
      const corePlanRoomTypes = corePlan.getCorePlanRoomTypes();
      const unreadyCorePlanTypes = corePlanRoomTypes.filter(rt => rt.assetsState !== RoomTypeAssetsState.LOADED);

      if (unreadyCorePlanTypes.length > 0) {
        await this.batchLoadRoomTypeAssets(unreadyCorePlanTypes);
      }

      // Lock corePlan
      if (!corePlan.isLocked()) {
        await entityManager.lockCorePlan(corePlan);
      }

      if (corePlan.isLockedByCurrentUser()) {
        corePlanLockHeartbeat.start();
      }
    }

    // change active corePlan
    runInAction(() => {
      this._activeCorePlan.set(corePlan ?? null);
      this.setGridUnitSizeInches(this.activeCorePlan?.attributes.gridUnitSize || settings.values.validationSettings.gridCellSizeForNewCorePlans);
      this.setIncludeCladdingThickness(this.activeCorePlan?.attributes.includeCladdingThickness ?? false);
      this.setActiveFloor(this.activeCorePlan?.floors?.length ? this.activeCorePlan.floors[0]?.id : null);
      this.setActiveValidationMode(ValidationMode.None);
      this.setPendingChanges(false);
    });
  }

  setActiveFloor(id: string | null | undefined): void {
    if (this.activeFloor?.id !== id) {
      this.selectedRoomsIds.length = 0;
      this.contextMenuOptions = { show: false };
      this.activeFloor = this.activeCorePlan !== null ? this.activeCorePlan.floors.find(floor => floor.id === id) : null;
    }
  }

  setActiveValidationMode(mode: ValidationMode) {
    this.activeValidationMode = mode;
  }
  setValidationResult(result: ValidationResult | null) {
    this.validationResult = result;
  }

  setContextMenuOptions(options: IContextMenuOptions): void {
    this.contextMenuOptions = options;
  }
  setTooltipOptions(options: ITooltipOptions) {
    this.tooltipOptions = options;
  }
  setEditToolOptions(options: IEditorToolOptions) {
    this.editToolOptions = options;
  }
  setPromptOptions(options: IPromptOptions) {
    this.promptOptions.onClose?.(null);
    this.promptOptions = options;
  }
  addSelectedRoomId(id: string): void {
    if (!this.selectedRoomsIds.includes(id)) {
      this.selectedRoomsIds.push(id);
    }
  }
  setSelectedRoomsIds(ids: string[]): void {
    this.selectedRoomsIds.length = 0;
    this.selectedRoomsIds.push(...ids);
  }
  deleteSelectedRoomsId(id: string): void {
    const idx = this.selectedRoomsIds.findIndex(x => x === id);
    if (idx !== -1) {
      this.selectedRoomsIds.splice(idx, 1);
    }
  }
  clearSelectedRoomsIds(): void {
    this.selectedRoomsIds.length = 0;
  }

  addSelectedLotItemId(id: string): void {
    if (!this.selectedLotItemsIds.includes(id)) {
      this.selectedLotItemsIds.push(id);
    }
  }
  deleteSelectedLotItemId(id: string): void {
    const idx = this.selectedLotItemsIds.findIndex(x => x === id);
    if (idx !== -1) {
      this.selectedLotItemsIds.splice(idx, 1);
    }
  }
  clearSelectedLotItemsIds(): void {
    this.selectedLotItemsIds.length = 0;
  }

  addSelectedRoomOpening(opening: RoomOpening): void {
    if (opening && !this.selectedRoomOpenings.includes(opening)) {
      this.selectedRoomOpenings.push(opening);
    }
  }
  deleteSelectedRoomOpening(opening: RoomOpening): void {
    const idx = this.selectedRoomOpenings.indexOf(opening);
    if (idx !== -1) {
      this.selectedRoomOpenings.splice(idx, 1);
    }
  }
  clearSelectedRoomOpenings(): void {
    this.selectedRoomOpenings.length = 0;
  }

  setSceneMode(val: SceneMode): void {
    this.sceneMode = val;
  }
  setSceneEditorMode(editMode: SceneEditorMode): void {
    this.setActiveFinish(null);

    this.sceneEditorMode = editMode;
  }

  setGridUnitSizeInches(val: number): void {
    val = Math.max(val, 1);
    this.gridUnitSizeInches = val;
    if (this.activeCorePlan && this.activeCorePlan.attributes.gridUnitSize !== val) {
      this.activeCorePlan.attributes.gridUnitSize = val;
      entityManager.savePartially(this.activeCorePlan, ["attributes"]);
    }
  }
  setIncludeCladdingThickness(value: boolean): void {
    this.includeCladdingThickness = value;
  }
  setShowRoof(showRoof: boolean): void {
    this.showRoof = showRoof;
  }
  setShowCladding(showCladding: boolean): void {
    this.showCladding = showCladding;
  }
  setDimensionType(showFinishFaceDimension: boolean): void {
    this.showFinishFaceDimension = showFinishFaceDimension;
  }
  setShowBackground(showBackground: boolean): void {
    this.showBackground = showBackground;
  }
  setShowGrid(val: boolean): void {
    this.showGrid = val;
  }
  setShowGridOverPlan(val: boolean): void {
    this.showGridOverPlan = val;
  }
  setShowObsoleteRooms(showObsoleteRooms: boolean): void {
    this.showObsoleteRooms = showObsoleteRooms;
  }
  setSnapToGrid(val: boolean): void {
    this.snapToGrid = val;
  }

  setActiveDesignStyle(designStyle: DesignStyle) {
    this.activeDesignStyle = designStyle;
  }
  setActiveFinish(finish: ExteriorFinish) {
    this.resetDesignSelection();
    this.activeFinish = finish;
  }

  setDutchGableDepth(depth: number) {
    if (depth < DUTCH_GABLE_MINIMUM_WIDTH) {
      this.dutchGableError = DUTCH_GABLE_SMALL_ERROR;
    } else if (depth >= DUTCH_GABLE_MINIMUM_WIDTH && this.dutchGableError) {
      this.dutchGableError = "";
    }
    this.dutchGableDepth = depth;
  }

  setDutchGableError(dutchGableError: string) {
    this.dutchGableError = dutchGableError;
  }
  setActiveRoofId(roofId: string) {
    this.resetDesignSelection();
    this.activeRoofId = roofId;
  }
  resetDesignSelection() {
    this.activeRoofId = null;
    this.activeFinish = null;
  }

  setActive3dFile(url: string) {
    this.active3dModelPresignedUrl = url;
  }

  undo(): void {
    if (this.sceneMode == SceneMode.Editor) {
      this.baseManager.roomManager.undo();
    }
  }
  redo(): void {
    if (this.sceneMode == SceneMode.Editor) {
      this.baseManager.roomManager.redo();
    }
  }

  async createExportFile(type: FileExportType): Promise<File> {
    switch (type) {
      case FileExportType.PdfCorePlan: {
        return new PdfReportTool(this.baseManager.roomManager).generatePdfReport();
      }
      case FileExportType.PdfPlan: {
        return new PdfReportTool(this.baseManager.roomManager).generatePdfReport(true);
      }
      case FileExportType.Json: {
        return await new CorePlanJsonTool(this.baseManager.roomManager).generateCorePlanJson();
      }
      case FileExportType.Tml: {
        if (this.activeCorePlan.isCostOutdated && !appModel.isViewOnlyMode) {
          await this.updateCorePlanCost();
        }
        return new TotalMaterialListTool(this.baseManager.roomManager).generateReport();
      }
    }
  }

  async batchLoadRoomTypeAssets(roomTypes: RoomType[]): Promise<void> {
    const promises = roomTypes.map(rt => this.loadRoomTypeAssets(rt));
    await Promise.all(promises);
  }

  async loadRoomTypeAssets(roomType: RoomType): Promise<void> {
    roomType.assetsLoading();

    if (!isWooMode()) {
      // isWoo === use mocks
      const dxfs = await fileManager.getRoomTypeDxfs(roomType.id);
      runInAction(() => {
        for (const dxfKey of Object.keys(dxfs)) {
          this.addDxfItem(new DxfItem(dxfKey, dxfs[dxfKey]));
        }
      });
    }

    roomType.assetsLoaded();
  }

  async updateCorePlanCost(): Promise<void> {
    // Remember in case user left the corePlan
    const corePlan = this.activeCorePlan;
    corePlan.setIsEstimatingCost(true);
    const jsonTool = new CorePlanCostTool(this.baseManager.roomManager);
    const corePlanCostJson = jsonTool.generateCorePlanCostJson();
    await jsonTool.updateCorePlanCost(corePlan, corePlanCostJson);
    corePlan.setIsEstimatingCost(false);
  }

  private makeObs(): void {
    makeObservable(this, {
      designStyles: observable,
      finishes: observable,
      roomCategories: observable,
      corePlans: observable,
      dxfStore: observable,
      activeAreaColors: observable,
      isBusy: observable,
      isSceneLoaded: observable,
      activeFinish: observable,
      isBackgroundScalingEnabled: observable,
      pendingChanges: observable,
      activeFloor: observable,
      selectedRoomsIds: observable,
      selectedLotItemsIds: observable,
      selectedRoomOpenings: observable,
      showAboveFloor: observable,
      showBelowFloor: observable,
      contextMenuOptions: observable,
      sceneMode: observable,
      sceneEditorMode: observable,
      editToolOptions: observable,
      tooltipOptions: observable,
      promptOptions: observable,
      gridUnitSizeInches: observable,
      includeCladdingThickness: observable,
      showRoof: observable,
      showCladding: observable,
      showBackground: observable,
      showGrid: observable,
      showGridOverPlan: observable,
      showObsoleteRooms: observable,
      snapToGrid: observable,
      activeValidationMode: observable,
      validationResult: observable,
      activeDesignStyle: observable,
      dutchGableDepth: observable,
      dutchGableError: observable,
      activeRoofId: observable,
      active3dModelPresignedUrl: observable,
      showFinishFaceDimension: observable,

      activeCorePlan: computed,
      activeCorePlanBedroomsCounts: computed,
      activeCorePlanBathroomsCounts: computed,

      addRoomCategory: action,
      removeRoomCategory: action,
      removeFromRoomCategory: action,
      markRoomTypeHidden: action,
      addCorePlan: action,
      removeCorePlan: action,
      addFinish: action,
      deleteFinish: action,
      setIsBusy: action,
      setIsSceneLoaded: action,
      setIsBackgroundScalingEnabled: action,
      setPendingChanges: action,
      setActiveCorePlan: action,
      setActiveFloor: action,
      setContextMenuOptions: action,
      setTooltipOptions: action,
      setPromptOptions: action,
      setActiveValidationMode: action,
      setValidationResult: action,
      addSelectedRoomId: action,
      setSelectedRoomsIds: action,
      deleteSelectedRoomsId: action,
      clearSelectedRoomsIds: action,
      addSelectedLotItemId: action,
      deleteSelectedLotItemId: action,
      clearSelectedLotItemsIds: action,
      addSelectedRoomOpening: action,
      deleteSelectedRoomOpening: action,
      clearSelectedRoomOpenings: action,
      setSceneMode: action,
      setSceneEditorMode: action,
      setGridUnitSizeInches: action,
      setIncludeCladdingThickness: action,
      setShowRoof: action,
      setShowCladding: action,
      setShowBackground: action,
      setShowGrid: action,
      setShowGridOverPlan: action,
      setShowObsoleteRooms: action,
      setSnapToGrid: action,
      setActiveDesignStyle: action,
      setDutchGableDepth: action,
      setActiveRoofId: action,
      setActive3dFile: action,
      undo: action,
      redo: action,
      updateCorePlanCost: action,
    });
  }

  private async initData(): Promise<void> {
    const fromDB = !isWooMode();

    if (fromDB) {
      try {
        this.setIsBusy(true);

        const results = await Promise.all([
          finishesManager.listFinishes(),
          settings.loadSettings(),
          catalogSettings.loadSettings(),
          revitFiles.loadFiles(),
          MockupsManager.initDesignStyles(), // TODO: temporary solution; replace by actual data from DB!!!
        ]);

        runInAction(() => {
          this.finishes.push(...results[0]);
          // TODO Currently take only one Design Style
          if (MockupsManager.designStyles[0]) {
            this.designStyles.push(MockupsManager.designStyles[0]);
            this.setActiveDesignStyle(this.designStyles[0]);
            appModel.activeDesignStyle.finishes = this.finishes;
          }
        });

        const categories = await entityManager.pullList(RoomCategory);
        runInAction(() => {
          this.roomCategories.push(...categories);
        });
        const corePlans = await entityManager.pullList(CorePlan);

        runInAction(() => {
          this.corePlans.push(...corePlans);
        });
      } finally {
        this.setIsBusy(false);
      }
    } else {
      await this.initMockupsData();
    }
  }

  private async initMockupsData(): Promise<void> {
    await MockupsManager.init();

    (this.designStyles as IObservableArray<DesignStyle>).clear();
    MockupsManager.designStyles.forEach(designStyle => this.addDesignStyle(designStyle));
    this.setActiveDesignStyle(this.designStyles[0]);

    (this.roomCategories as IObservableArray<RoomCategory>).clear();
    MockupsManager.roomCategories.forEach(category => this.addRoomCategory(category));

    (this.corePlans as IObservableArray<CorePlan>).clear();
    MockupsManager.corePlans.forEach(corePlan => this.addCorePlan(corePlan));
  }
}

export const appModel = new AppModel();
