import log from "loglevel";
import { addAuthParam, getAxiosRequestConfig } from "../services/api/utilities";
import apiProvider from "../services/api/utilities/Provider";
import axiosClient from "../services/api/utilities/axios-wrapper";
import { Variation } from "../models/Variation";
import { appModel } from "../models/AppModel";
import { RawVariation } from "../services/rawTypes";
import { showToastMessage } from "../helpers/messages";
import { entityManager, DTOConvertOptions } from "./entityManager";

class VariationsManager {
  public async saveVariation(variationName: string): Promise<Variation> {
    try {
      const saveMode: DTOConvertOptions = { scope: "full", mode: "new" };
      const variation = new Variation(variationName);
      variation.corePlanId = appModel.activeCorePlan.id;
      const roomsData = [];

      appModel.activeCorePlan.floors.forEach(floor => {
        floor.rooms.forEach(room => {
          roomsData.push({
            roomGuid: room.id,
            colorIndex: room.customColorIndex,
            roofSlopes: room.roofSlopes,
            dutchGableDepths: room.dutchGableDepths,
            exteriorFinishes: room.exteriorFinishes,
            gableExteriorFinishes: room.gableExteriorFinishes,
          });
        });
      });

      variation.data = { roomsData: roomsData };
      const { result } = await entityManager.save(variation, "APIdata", saveMode, true);
      return result;
    } catch (e) {
      log.error(e);
    }
    return null;
  }

  public async deleteVariation(variationId: string): Promise<boolean> {
    try {
      const url = `${apiProvider.host}variations/${variationId}`;
      const response = await axiosClient.delete(url, getAxiosRequestConfig());
      console.log("Variation deleted successfully:", response.data);
      return true; // Assuming deletion was successful if no exceptions were thrown
    } catch (e) {
      log.error("Failed to delete variation:", e);
      return false; // Return false if there was an error during the deletion process
    }
  }

  public async loadVariation(variation: Variation): Promise<boolean> {
    try {
      // Fetch the variation details from the server
      const response = await axiosClient.get(`${apiProvider.host}variations/${variation.id}`, getAxiosRequestConfig());
      const fetchedVariation = response.data.data;

      // Check if the data property exists and is already an object (not a string)
      if (!fetchedVariation.data || typeof fetchedVariation.data !== "object") {
        console.log(`Invalid or missing data for variation: ${fetchedVariation.name}`);
        return false;
      }

      // Directly use the data if it's already an object
      const parsedData = fetchedVariation.data;

      // Check if roomsData exists and is an array
      if (parsedData.roomsData && Array.isArray(parsedData.roomsData)) {
        parsedData.roomsData.forEach(async roomData => {
          const roomGuid = roomData.roomGuid;
          const colorIndex = roomData.colorIndex;
          const roofSlopes = roomData.roofSlopes;
          const dutchGableDepths = roomData.dutchGableDepths;
          const exteriorFinishes = roomData.exteriorFinishes;
          const gableExteriorFinishes = roomData.gableExteriorFinishes;

          await appModel.baseManager.previewManager.loadRoomVariation(
            roomGuid,
            colorIndex,
            roofSlopes,
            exteriorFinishes,
            gableExteriorFinishes,
            dutchGableDepths
          );
        });
        await appModel.baseManager.previewManager.loadSoPreviewRooms();
        return true; // Return true to indicate success
      }

      return false; // Return false to indicate failure or no data to process
    } catch (e) {
      log.error(e);
      return false; // Return false in case of an exception
    }
  }
}

export const variationsManager = new VariationsManager();
